<template>
  <div class="races-events-area-a">
    <Tabs :tabs="enabledTabs"
          :activeTab="activeTab"
          v-bind="$attrs"
          @change="updateTab"></Tabs>
    <component v-bind:is="activeTab.component"
               v-bind="$attrs"/>
  </div>
</template>

<script>
import { each, filter, findIndex } from 'lodash';
import { mapGetters } from 'vuex';
import { Tabs } from '@nsftx/games-sdk-js';
import RacesEventsStatisticsA from '@/components/RacesEventsStatisticsA';
import RacesEventsResultsA from '@/components/RacesEventsResultsA';
import Boost from '@/components/Boost';

export default {
  name: 'RacesEventsAreaA',
  components: {
    Tabs,
    RacesEventsStatisticsA,
    RacesEventsResultsA,
    Boost,
  },
  data() {
    return {
      activeTab: {},
      enabledTabs: [],
    };
  },
  computed: {
    ...mapGetters([
      'translations',
      'isBoostActive',
      'isMobile',
      'user/user',
    ]),
    userProfile() {
      return this['user/user'];
    },
    component() {
      return this.activeTab;
    },
    components() {
      return [
        {
          name: 'Boost',
          label: 'Activity',
          component: Boost,
          disabled: !this.isBoostActive || this.isMobile,
        },
        {
          name: 'RacesEventsStatisticsA',
          label: this.translations.general_statistics,
          component: RacesEventsStatisticsA,
          disabled: false,
        },
        {
          name: 'RacesEventsResultsA',
          label: this.translations.general_results,
          component: RacesEventsResultsA,
          disabled: false,
        },
      ];
    },
  },
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    updateTab(event) {
      const activeIndex = findIndex(this.enabledTabs, (tab) => event.id === tab.id);
      this.activeTab = this.enabledTabs[activeIndex];
    },
  },
  inheritAttrs: false,
  created() {
    this.enabledTabs = filter(this.components, (tab) => !tab.disabled);

    each(this.enabledTabs, (t, i) => {
      const tab = t;
      tab.id = i;
    });
    [this.activeTab] = this.enabledTabs;
  },
  watch: {
    'userProfile.profile.id': function reinitializeActiveComponent() {
      this.activeTab = {};
      setTimeout(() => {
        [this.activeTab] = this.enabledTabs;
      }, 100);
    },
  },
};
</script>
