<template>
  <div class="racer-label-wrapper">
    <div class="racer-label"
         :style="racerLabelStyle">
      <span>{{ ordinalNumber }}</span>
    </div>
  </div>
</template>

<script>
import { isArray } from 'lodash';
import { convert, helpers } from '@nsftx/games-sdk-js';

export default {
  name: 'RacerLabel',
  props: {
    ordinalNumber: {
      type: [String, Number],
      default: 1,
      required: true,
    },
    color: {
      type: [Array, String],
      default: () => (['000', 'fff']),
    },
    orientation: {
      type: String,
      default: 'right',
    },
    height: {
      type: [String, Number],
      default: '50',
    },
  },
  computed: {
    mainColor() {
      return isArray(this.color) ? this.getValidColor(this.color[0])
        : this.getValidColor(this.color);
    },
    secondaryColor() {
      return isArray(this.color) && this.getValidColor(this.color[1]);
    },
    racerLabelStyle() {
      const style = {};
      if (this.mainColor) {
        style.background = convert.hexToRgba(this.mainColor, 30);
        style[`border-${this.orientation}`] = `5px solid ${this.mainColor}`;
        style.height = `${this.height}px`;

        if (this.secondaryColor) {
          const gradientDirection = this.orientation === 'right' || this.orientation === 'left' ? 'bottom' : 'right';

          style['border-image-source'] = `linear-gradient(to ${gradientDirection}, ${this.mainColor} 33.33%, ${this.secondaryColor} 0%, ${this.secondaryColor} 66.66%, ${this.mainColor} 0%)`;
          style['border-image-slice'] = 5;
        }
        return style;
      }
      return style;
    },
  },
  methods: {
    getValidColor(color) {
      const validColor = helpers.isValidColor(color);
      if (!validColor) {
        const hexColor = `#${color}`;
        return helpers.isValidColor(hexColor) ? hexColor : '';
      }
      return validColor;
    },
  },
};
</script>

<style scoped lang="scss">
.racer-label-wrapper {
  background: var(--card-bg);
}
.racer-label {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-primary-1);
  font-size: 14px;
  font-weight: 500;
  border-radius: 2px;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
</style>
