<template>
  <div class="races-events-results-a">
    <div class="round-row"
         v-for="(round, index) in $attrs.resultsData"
         :key="index">
      <Accordion :title="`${$attrs.roundLabel} ${round.eventId}`"
                 :is-expanded="isExpanded(round.eventId)"
                 @click.native="setCurrentAccordion(round.eventId)">
        <div class="round-info">
          <div class="order-header">
          <span v-for="(item, key) in ranking"
                :key="key">
            {{ key + 1 }}.
          </span>
          </div>
          <div class="order-body">
            <div class="label">
              <RacerLabel v-for="(racer, key) in ranking"
                          :ordinalNumber="round.result[racer].racers"
                          :color="$attrs.colors[round.result[racer].racers - 1]"
                          orientation="bottom"
                          :key="key"
                          height="40">
              </RacerLabel>
            </div>
          </div>
        </div>
      </Accordion>
    </div>
  </div>
</template>

<script>
import { Accordion } from '@nsftx/games-sdk-js';
import RacerLabel from '@/components/RacerLabel';

export default {
  name: 'RacesEventsResultsA',
  components: {
    Accordion,
    RacerLabel,
  },
  data() {
    return {
      ranking: ['first', 'second', 'third'],
      currentAccordion: null,
    };
  },
  methods: {
    isExpanded(eventId) {
      return this.currentAccordion === eventId;
    },
    setCurrentAccordion(eventId) {
      this.currentAccordion = eventId;
    },
  },
};
</script>

<style lang="scss" scoped>
  .races-events-results-a {
    background-color: var(--card);
    padding: 16px 8px;
    float: left;
    width: 100%;

    .round-info {
      float: left;
      width: 100%;
      padding-bottom: 16px;
      .order-header {
        padding: 16px 0 8px 0;
        display: flex;
        justify-content: space-around;
        color: var(--text-primary-1);
        opacity: 0.6;
        font-size: 14px;
        user-select: none;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
      }
      .label {
        display: flex;

        .racer-label-wrapper {
          width: 100%;
          margin: 1px;
        }
      }
    }
  }
</style>
