<template>
  <div class="races-events-statistics-a">
    <div class="statistic-area">
      <div class="racer-labels">
        <RacerLabel
          v-for="(racer, index) in $attrs.racers"
          :ordinalNumber="racer"
          :color="$attrs.colors[index]"
          height="40"
          orientation="right"
          :key="index">
        </RacerLabel>
      </div>
      <div class="racers-wrapper">
        <div class="racers-row"
             v-for="racer in $attrs.racers"
             :key="racer">
          <div class="position"
               v-for="(value, index) in $attrs.statsData[racer]"
               :key="index"
               :class="{'first' : value == '1'}">
            {{ positionLabel(value) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RacerLabel from '@/components/RacerLabel';

export default {
  name: 'RacesEventsStatisticsA',
  components: {
    RacerLabel,
  },
  methods: {
    positionLabel(value) {
      if (value === 0) {
        return 'N/A';
      }
      return `${value}.`;
    },
  },
};
</script>

<style lang="scss" scoped>
  .races-events-statistics-a {
    .statistic-area {
      display: flex;
      padding: 16px 8px;
      background-color: var(--card);
      .racer-labels {
        width: 20%;
      }
      .racers-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        .racers-row {
          display: flex;
          height: 40px;
          margin: 0 0 1px 1px;
        }
        .position {
          flex-grow: 1;
          background-color: var(--button);
          color: rgba(#ffffff, 0.3);
          color: var(--text-primary-1);
          font-size: 14px;
          text-align: center;
          line-height: 40px;
          height: 100%;
          border-radius: 2px;
          margin: 1px;
          width: 100%;
          user-select: none;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          &.first {
            background-color: var(--button);
            color: var(--text-primary-1);
          }
        }
      }
    }
  }
</style>
