import { render, staticRenderFns } from "./RacesEventsStatisticsA.vue?vue&type=template&id=2f5b8792&scoped=true"
import script from "./RacesEventsStatisticsA.vue?vue&type=script&lang=js"
export * from "./RacesEventsStatisticsA.vue?vue&type=script&lang=js"
import style0 from "./RacesEventsStatisticsA.vue?vue&type=style&index=0&id=2f5b8792&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f5b8792",
  null
  
)

export default component.exports